.mobile_notifications {
  &__wrapper {
    width: 100%;
    max-height: 812px;
    background: #FFFFFF;
    padding: 20px;
  }
}

.new-notifications__counter {
  padding: 2px 4px;
  width: 20px;
  height: 20px;
  font-weight: 500;
  font-size: 12px;
  background: #FF655C;
  color: white;
  border-radius: 200px;
  margin-left: 8px;
}
