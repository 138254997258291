.nav {
  &__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 20px;
    margin: 0;
    background: #65696d;
  }
  &__item {
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: larger;
    margin-left: 40px;
    color: white;
    &:hover {
      color: #cacccb;
    }
    &_active {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: larger;
      margin-left: 40px;
      color: gold;
      &:hover {
        color: #cacccb;
      }
    }
  }
}

.leftSide_section {
  display: flex;
  justify-content: center;
  width: 50%;
  margin-left: 25px;
  margin-top: 15px;
}

.added_div {
  width: 100%;
  display: flex;
  align-items: center;
}

.added_div_portfolio {
  margin-top: 15px;
  max-height: 150px;
  overflow-y: auto;
}

.added_div_skills {
  margin-top: 15px;
  max-height: 350px;
  overflow-y: auto;
}

.added_input {
  border: 1px solid grey;
  overflow-wrap: break-word;
  border-radius: 5px;
  padding: 8px;
  outline: none;
  width: 100%;
  margin: 10px 0;
}

.book_image {
  width: 100%;
  height: 180px;
  border: 1px solid #a3a3a3;
  background-size: 101% 101%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #ccc;
}

.fullwrap {
  position: relative;
  width: 23%;
  height: 250px;
  border: 1px solid #cacccb;
  padding: 10px
}

.fullwrap img {
  width: 90%;
  height: 90%;
  object-fit: cover;
}

.fullcap {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.fullcap {
  opacity: 0;
  transition: opacity 0.3s;
}

.fullwrap:hover .fullcap {
  visibility: visible;
  opacity: 1;
}

.save_button a {
  color: white;
}
