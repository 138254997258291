.login-form {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    
  &__container {
    width: 620px;
    height: 460px;
    border-radius: 20px;
    box-shadow: 0px 3px 8px rgba(0, 38, 91, 0.1), 0px 4px 12px rgba(0, 38, 91, 0.08), 0px 1px 20px rgba(0, 38, 91, 0.04);
  }

  &__password_label{
    background-color: white;
  }

  &__helper_label{
    color: #FF655C;
  }

  & form {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__col {
    display: flex;
    flex-direction: column;

    align-items: center;
    height: 100%;
    gap: 4rem;
    padding: 10%;
  }

  &__form-group {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  }

  &__form-label {
      display: inline-block;
      font-size: 23px;
      font-weight: 600;
  }

  &__input {
      @include form-control;
   }

  &__input-error {
    @include form-control;

    border: 1px solid $error;

    &:focus {
      outline: 1px solid $error;;
    }
  }
    fieldset {
        legend {
            width: unset;
        }
    }
}

input[type='password'] {
    letter-spacing: 0.3em;
}

@media (max-width: 550px) {
  .login-form form{
    justify-content: unset;
    gap: 5rem;
  }
  .login-form__container {
    height: 100%;
  }
  .login-form__container {
    box-shadow: none;
  }
  .login-form__col {
    padding: 0;
  }
  .login-form__form-label{
    padding-top: 50%;
  }
  .login-form__form-group{
    flex-grow: 1;
  }
}
