.dashboard {
  &_main {
    &__filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 50px
    }

    &__select {
      width: 300px;
      margin-right: 15px;
    }

    &__tableName {
      font-weight: 600;
      font-size: 18px;
      line-height: 142%;
      color: #303540;
      margin-left: 40px;
      padding: 17px 0
    }
  }

  &_manager {
    &__tab {
      width: 50%;
      padding: 50px 0
    }
  }

  &_nav {
    &__list {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 5px;
      background: #fff;
      border: 1px solid #D4D9E2;
      border-radius: 8px;
    }

    &__item {
      text-decoration: none;
      font-size: 14px;
      padding: 17px 30px;
      color: #444A58;
      &-active {
        font-size: 15px;
        padding: 17px 30px;
        color: white;
        background: #444A58;
        border-radius: 4px;
        text-decoration: none;
        &:hover {
          color: white
        }
      }
    }
  }
}

.dashboard_user_cv_link {
  display: inline-block;
  text-decoration: none;
  padding: 13px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #8992A6;
  background: #EEEFF4;
  border-radius: 6px;
  margin: 3px 3px;
}

.dashboard_pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.managers {
  &_table {
    &-head {
      margin-right: 45%;
    }

    &-teamList {
      width: 50%;
      margin-bottom: 10px
    }

    &-teamItem {
      display: flex;
      flex-direction: column;
    }

    &-projectsList {
      display: flex;
    }

    &-projectsItem {
      width: 50%;
    }
  }
}

.leaves {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__progress {
    width: 68px;
    margin-left: 10px;
  }
}

.MuiTableContainer-root {
  all: initial;
}
