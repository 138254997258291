.admin-report{
    &__title{
        margin: 20px;
        font-weight: 600;
    }

    &__title_counter{
        margin-left: 7px;
        background: #FEF3F2;
        border-radius: 25px;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 14px;
        text-align: center; 
        color: #FF5641;
    }

    &__tabs{
        margin: 0;
        padding: 18px 6px;
    }
    
    &__mobile_tabs{
        margin: 0;
        padding: 17px 5px;
    }

    &__mobile_tabs > li{
        padding: 13px 70px;
    }

    &__csv_buttons{
        display: flex;
        gap: 2rem;
    }

    &__csv_mobile_buttons{
        display: flex;
        margin: 20px 20px 0 20px;
        gap: 1rem;
    }

    &__filters{
        &_container{
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: space-between;
        }
        &_select{
            width: 50%;
        }
        &_mobile_select{
            margin-top: 1rem;
            width: 100%;
        }
        &_left{
            display: flex;
            gap: 1rem;
        }
        &_mobile{
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__footer{
        padding-bottom: 10px;
    }

    &__mobile_footer{
        background-color: #fff;
    }

    &__mobile_bg{
        background-color: #F9F9FA;
    }

    &__mobile_bg > nav{
        background-color: #F9F9FA;
        margin-bottom: 0;
        padding-bottom: 30px;
    }

    &__mobile_filters{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 0;
    }

    &__mobile_filters > svg{
        width: 30px;
        height: 30px;
    }

    &__mobile_nodata{
        color: #747F96;
        padding-top: 80%;
        padding-bottom: 100%;
        text-align: center;
    }

    &__counter{
        margin-left: 7px;
        background: #FF5641;
        border-radius: 15px;
        padding: 5px 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: center; 
        color: #FEF3F2;
    }

    &__wrapper{
        align-items: initial;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px !important;
    }

    &__csv_button{
        text-decoration: none;
        color: white;
        background: #444A58;
        border-color: transparent;
        border-radius: 10px;
        padding: 19px;
        transition: 0.3s linear;
    }

    &__csv_button_icon{
        margin-right: 10px;
    }

    &__csv_button:hover{
        text-decoration: none;
        color: white;
        background: #595c64;
    }

    &__csv_button:hover:enabled {
        color: white;
        background: #595c64;
        border-color: transparent;
    }
      
      
    &__csv_button:active:enabled,
    &__csv_button:focus{
        background: #595c64;
    }

    &__outlined{
        border: 2px solid #EEEFF4;
        color: #444CE7;
        background: white;
    }
}
