.update-user-form {
  &__group {
    margin-bottom: 1rem;
  }

  &__select{
    @include form-control
  }

  &__button {
    width: 100%;
    height: 41px;
  }
}
