@mixin form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $trout;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $Ghostapprox;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}

@mixin datePicker {
  text-align: center;
  padding-bottom: 2em;
}

@mixin totalText {
  padding-top: 1em;
  text-align: right;
  font-size: 1.1em;
  white-space: nowrap;
}

@mixin dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover, :focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
  }


}
