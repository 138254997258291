.create-record {
  &__main {
    display: flex;
    gap: 1rem;

    @media (max-width: $layout-mobile) {
      flex-direction: column;
    }
  }

  &__form {
    @include form-control;

    @media (max-width: $layout-mobile) {
      margin: 10px;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: $layout-mobile) {
      
    }
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    @media (max-width: $layout-mobile) {
      width: 100%;
    }
  }

  &__col:last-child {
    flex-grow: 1;
  }
  &__col--checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25%;
    margin-top: 9px;

    @media (max-width: $layout-mobile) {
      margin: 10px;
      max-width: 95%;
    }
  }

  &__col--sick-leave-select{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    @media (max-width: $layout-mobile) {
      width: 100%;
    }
  }

  &__col--vacation {
    display: flex;
  }

  &__col--buttons {
    display: flex;
    @media (max-width: $layout-mobile) {
      display: block;
    }
  }

  &__col--image{
    display: flex;
    width: 10%;
    align-items: center;
    cursor: pointer;
  }

  &__col--text{
    margin-left: 5px;
  }

  &__col--calendar{
    margin-left: 50px;
  }

  &__col--button {
    width: 100%;

    @media (max-width: $layout-mobile) {
      margin-top: 20px;
    }
  }

  &__button {
    width: 100px;
    height: 41px;
  }
}
