.projects-list {
  &__order {
    width: 3%;
  }

  &__names {
    width: 87%;
  }

  &__actions {
    width: 10%;
  }
}
