.candidate-info {
  .name {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-bottom: 0;
    }
  }

  .name-buttons {
    display: flex;
    gap: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.tech-info {
      margin: 16px 0;

      li:not(:last-child) {
        margin-bottom: 8px
      }
    }

    &.contact-info {
      li {
        margin-bottom: 28px
      }
    }

    li {
      color: $gray-600;

      strong {
        color: $gray-900;
      }
    }
  }
}

.ping {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    margin-right: 12px;
  }
}

.significate-select-main {
  border: 0;
  color: #667085;
  background: transparent;
}

.candidate-notes {
  padding-left: 56px;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    h3 {
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .significate {
      width: 340px;
    }
  }

  .notes {
    td {
      white-space: initial;
    }

    tr.note-title {
      border-bottom: 1px solid #D4D9E2;
      th {
        padding: 15px 40px;
      }
    }

    .note-body {
      &-text {
        width: 100%;

        .form-control {
          resize: none;
          border: none;
        }
      }
      &-button {
        width: 120px;
      }

      .btn {
        box-shadow: 0px 3px 5px rgba(177, 189, 210, 0.37);
        border: 1px solid #EEEFF4;

        &:first-of-type {
          margin-bottom: 10px;
        }

        &:disabled {
          opacity: 0.2;
        }
      }
    }
  }
}

.label-significate {
  position: relative;
  padding-left: 17px;

  &::before {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    position: absolute;
    left: 0;
    top: 30%;
    display: block;
    height: 12px;
    width: 12px;
  }

  span {
    color: $gray-600;
  }
}

.btn-copy {
  border: 0;
  margin-left: 15px;
  background-color: transparent;
}
