.reports-filters {

  &__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  &__col {
    flex: 0 0 16.7%;
    max-width: 16.7%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    &--3 {
      flex: 0 0 25%;
      max-width: 25%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }

    &--4 {
      margin: 0 auto;

      &--checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }
    }
  }

  &__form {
    @include form-control
  }
}
