.filters-modal-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .customers-button-submit {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .filters-modal-navbar {
    display: flex;
    align-items: center;
    flex-direction: row;
    box-shadow: 0 4px 8px rgba(0, 38, 91, 0.08), 0 1px 4px rgba(0, 38, 91, 0.04);
    padding: 28px 24px;

    .filters-modal-back-arrow {
      display: flex;
      align-items: center;
      margin-right: 22px;
    }

    .filters-modal-navbar-text {
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: #303540;
      margin: 0;
    }
  }

  .filters-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .customers-input-modal-wrapper {
      width: 335px;
      margin-top: 27px;
      margin-left: 20px;
      height: 100%;

      .customers-row {
        margin-top: 5px;

        .customers-input {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 14px;
          gap: 12px;
          width: 335px;
          height: 48px;
          background: #FFFFFF;
          border: 1px solid #D4D9E2;
          border-radius: 6px;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }

      .customers-create-row {
        margin-top: 20px;
      
        .customers-input {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 14px;
          gap: 12px;
          width: 335px;
          height: 48px;
          background: #FFFFFF;
          border: 1px solid #D4D9E2;
          border-radius: 6px;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .filters-modal-text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 49px;

      .candidates-filters-modal-text {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        letter-spacing: 0.01em;
        color: #303540;
        padding-left: 20px;
      }
    }

    .filters-modal-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .filters-modal-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        background: #444A58;
        border-radius: 8px;
        padding: 17px 40px;
        gap: 10px;
        margin-bottom: 20px;

        .customers-button {
          height: 52px;
        }

        .filters-modal-button-text {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;
          color: #FFFFFF;
          margin: 0;
        }
      }
    }
  }
}
