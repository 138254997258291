.candidate-filter-main-container {
  display: flex;
  margin-right: 16px;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 6px 18px 6px 6px;
  gap: 24px;
  width: 512px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #D4D9E2;
  border-radius: 8px;

  .candidate-filter-left-content {
    width: 39%;
  }

  .candidate-filter-right-content {
    width: 51%;
  }
}
