.settings-form {


  &__container {
    width: 620px;
    height: 460px;
    border-radius: 20px;
    box-shadow: 0px 3px 8px rgba(0, 38, 91, 0.1), 0px 4px 12px rgba(0, 38, 91, 0.08), 0px 1px 20px rgba(0, 38, 91, 0.04);
  }

  & form {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

//  &__form-group {
//    margin-bottom: 1.3rem;
//  }

  &__form-label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  &__button {
    width: 100%;
    height: 38px;
  }
}
