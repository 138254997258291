.action-bar{
  &__container{
    display: flex;
    flex-direction: column; 
    width: 90%;
    margin: 0 auto; 
    margin-bottom: 20px;
  }

  &__divider{
    width: 100%;
    height: 1px;
    background-color: #D4D9E2;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  &__buttons{
    display: flex;
  }
}