.total {
  &__row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    justify-content: center;

    &_create_page{
      justify-content: flex-end;
    }
  }

  &__col {
    margin-right: 30px;
    margin-top: 20px;
  }

  &__text {
    @include totalText;
  }
}
