.modal {
  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(137, 146, 166, 0.6);
    backdrop-filter: blur(4px);
    z-index: 10;
  }

  &__content-container {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 56px;
    box-shadow: 0px 3px 8px rgba(0, 38, 91, 0.1), 0px 4px 12px rgba(0, 38, 91, 0.08), 0px 1px 20px rgba(0, 38, 91, 0.04);
    border-radius: 12px;
    overflow: auto;
    max-height: 95vh;
    max-width: 900px;

    @include media-breakpoint-down(lg) {
      min-width: 75vw;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 20px 20px;
      max-height: 100vh;
      height: 100%;
      width: 100%;
      border-radius: 0;
      min-width: unset;
    }
  }

  &__header {
    @include media-breakpoint-down(sm) {
      margin: 0 calc(-1 * var(--bs-gutter-x, 13.28px)) 32px;
      display: flex;
      align-items: center;
      height: 80px;
      padding: 17px var(--bs-gutter-x, 13.28px);
      box-shadow: 0px 4px 8px rgba(0, 38, 91, 0.08), 0px 1px 4px rgba(0, 38, 91, 0.04);
    }
  }

  &__close-button.MuiButton-root {
    position: absolute;
    top: 32px;
    right: 32px;
    min-width: unset;
    border-radius: 100%;

    @include media-breakpoint-down(sm) {
      padding: 0.25rem 0.75rem;
      position: initial;
      margin-right: 20px;
    }
  }

  &__title {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #303540;
    margin-bottom: 40px;
    text-transform: capitalize;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
      font-size: 19px;
      line-height: 118%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .MuiOutlinedInput-root {
      & fieldset {
        border-color: #d4d9e2;
        border-radius: 8px;
      }
    }

    .MuiInputLabel-root {
      color: #9ea5b8;
    }

    .MuiBox-root {
      gap: 0;
      height: 56px;

      .MuiInputAdornment-root {
        margin-left: 0;
      }
    }
  }

  &__double-field,
  &__quad-field {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__quad-field {
    @include media-breakpoint-down(lg) {
      flex-direction: row;
    }
  }

  &__input,
  &__input.MuiFormControl-root {
    width: 500px;
    display: flex;
    gap: 16px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &__button.MuiButton-root {
    width: 100%;
    padding: 21px 0;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.01em;

    &.modal__cancel {
      border: 2px solid #eeeff4;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &.modal__submit {
      background: #444a58;

      &:hover {
        background: #353a45;
      }
    }
  }
}
