.manage-users {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
  }

  &__col {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
  }

  &__table {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
    width: 1400px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
  }
}

.sorting__text {
  font-size: 18px;
  margin-left: 25px;
  margin-right: 5px;
  font-weight: 500;
}

.sorting__select {
  width: 130px;
  outline: none;
  padding: 8px;
  background: none;
  border: 1px solid rgb(204, 200, 200);  

  &_option {
    font-size: 15px;
  }
}

.search__input {
  display: flex;
  margin-left: 15px;
}
