.custom-button {
  width: 100%;
  color: white;
  background: #444A58;
  border-color: transparent;
  border-radius: 10px;
  height: 60px;
  transition: 0.3s linear;
}

.prefix-icon{
  margin-right: 10px;
}

.outlined-custom-button{
    width: 100%;
    border: 2px solid #EEEFF4;
    background: transparent;
    color: #444CE7;
    border-radius: 10px;
    transition: 0.3s linear;
    height: 60px;
}

.custom-button:disabled, .outlined-custom-button:disabled {
  color: #8992A6;
  background: #EEEFF4;
  border-color: transparent;
}

.custom-button:hover:enabled {
  color: white;
  background: #595c64;
  border-color: transparent;
}


.custom-button:active:enabled,
.custom-button:focus{
    background: #595c64;
}
.outlined-custom-button:hover,
.outlined-custom-button:active,
.outlined-custom-button:focus {
  background: #EEEFF4;
}
