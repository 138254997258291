.create-project {
  &__row {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  &__input {
    @include form-control;
  }
}

.form_select__error{
  font-size: 12px;
  color: #d32f2f;
  margin-left: 12px;
  margin-top: 5px;
}

.form_select__datepicker {
  width: 100%;
}

.form_checkbox_group__error{
  border: 1px solid red;
  padding: 5px
}
