.user-report-filters {
  &__link {
    font-size: 1.4em;
  }

  &__container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

@keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}
