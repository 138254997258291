.breadcrumb-main-container {
  margin: 48px auto 40px;
  display: flex;

  .breadcrumb-main-span {
    margin-right: 10px;

    .breadcrumb-nav-link {
      text-decoration: none;
      color: #747F96;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.01em;
      font-weight: 500;

      &:hover {
        color: black;
      }
    }

    .current {
      color: #6172F3;
    }

    .calendar {
      padding-right: 10px;
    }
  }
}

.breadcrumb-home-icon {
  margin-right: 5px;
  padding-bottom: 3px;

  &:hover {
    transform: scale(1.2);
  }
}
