.nav-bar {
  background-color: $athensGray;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  @media (min-width: $layout-tablet) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  &__nav-container {
    flex-basis: 100%;
    flex-grow: 1;
    flex-direction: row-reverse;
    align-items: center;

    &:not(.show) {
      @media (max-width: $layout-tablet) {
        display: none;
      }
    }

    @media (min-width: $layout-tablet) {
      display: flex;
      flex-basis: auto;
    }
  }

  &__navigation{
    margin-left: 0;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    @media (min-width: $layout-tablet) {
      flex-direction: row;
    }
  }

  &__modal-button {
    text-transform: capitalize;
    font-size: 19px
  }

  &__nav-dropdown {
    float: none;
    display: block;

    top: 100%;
    left: 0;
    z-index: 1000;
    margin: 0px;
    min-width: 10rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    @media (min-width: $layout-tablet) {
      position: absolute;
    }
  }

  &__brand {
    display: flex;
    color: rgba(0, 0, 0, 0.9);
    align-items: center;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }

  &__item {
    @include dropdown-item
  }

  &__toggle {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-appearance: button;
    border-radius: 0.25rem;

    &__icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      background: 50% / 100% 100% url("../../images/toggle.svg");
    }

    @media (min-width: $layout-tablet) {
      display: none;
    }
  }
}
