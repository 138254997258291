.navbar {
  background-color: $gray-250 !important;
  min-height: 80px;
  padding: 0;
  border-bottom: 1px solid $gray-500;

  &__title{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5rem;
    margin-left: 1rem;
    font-weight: 700;
    font-size: 19px;
    justify-content: space-between;
  }
  &__title-request{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5rem;
    margin-left: 1rem;
    font-weight: 700;
    font-size: 19px;
    margin-right: 1rem;
    width: 77%;
    justify-content: space-between;
  }

  > .container-fluid {
    height: 100%;
  }
}

.navbar-list{
  a {
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  padding: 15px 28px !important;

  &__nested{
    padding: 15px 28px !important;
    display: flex;
    flex-direction: column;
    background-color: #F9F9FA;
    border-top: 3px solid #FF655C;
  }
}

.nav-link {
  box-sizing: content-box;
  color: $dark !important;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
  position: relative;
  transition: 0.3s linear;
}

.nav-link:hover{
    color: $orange !important;
}

.navbar-collapse {
  max-height: 100%;
}

.navbar-light .navbar-nav .nav-link.active::after {
  background-color: $secondary;
  height: 3px;
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -3px;
}

@media (max-width: 768px) {
  .navbar{
    background-color: white !important;
    box-shadow: 0px 4px 8px rgba(0, 38, 91, 0.08), 0px 1px 4px rgba(0, 38, 91, 0.04);

    .candidates-nav-icon {
      position: absolute;
      right: 13px;
      padding: 10px;
    }
  }
  .navbar a{
    text-decoration: none;
  }
  .navbar > .container-fluid{
    justify-content: flex-start;
    text-align: center;
  }

  .navbar-nav, .navbar-brand{
    display: none;
  }
}

.user-menu {
  background-color: $gray-500;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  padding: 0 !important;
  max-height: 100%;

  .user-menu-dropdown {
    right: 0;
    left: auto;
  }

  .nav-link {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 48px;
    width: 48px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
}
.custom_mobile_navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &__arrow {
    padding: 0 20px;
  }
  &__username {
    font-weight: 600;
    font-size: 19px;
    line-height: 118%;
    color: #303540;
  }
  &__buttons {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }
  &__edit {
    margin-right: 15px;
  }
}
