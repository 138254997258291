.update-form {
  &__form-group {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__select {
    width: 100%;
    @include form-control;
  }

  &__input {
    @include form-control;
  }

  &__button {
    width: 100%;
    height: 41px;
  }
}
