.date-picker {
  &__form {
    text-align: center;

    @media (max-width: $layout-mobile) {
      padding-bottom: 0;
    }
  }

  &__data-window {
    @include form-control;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }

  &__col {
    display: flex;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
  }

  &__total-text {
    @include totalText;
  }
}

.vacation {
  text-align: center;
  padding: 20px 0;
  font-size: x-large;
}
