.page-top {
  display: flex;
  justify-content: space-between;
  padding: 48px 0 24px;
}

.with-filters {
  justify-content: right !important;
  align-items: center;
}

.divider {
  display: block;
  width: 100%;
  height: 0px;
  border: 1px solid $gray-100;
  margin: 40px 0;
}

.candidates-add-button-mobile {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}

.candidates-filter-icon {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 5;
}
