label {
  font-size: 14px;
  color: #747f96;
}

.labeled {
  position: relative;

  label {
    background-color: $white;
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 3px;
  }

  .form-control-lg {
    border-radius: 0.5rem;
  }
}

.drop-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  width: 100%;
  background: #f5f8ff;
  border: 1px dashed #c7d7fe;
  border-radius: 8px;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    padding: 12px;
  }

  &-preview {
    &-container {
      width: 100%;
      display: flex;
      padding: 16px 0 16px 16px;
      justify-content: space-between;
      background-color: white;
      border-radius: 8px;

      .MuiButton-root {
        min-width: 30px;
        height: 30px;
        border-radius: 100%;
      }
    }

    &-image-wrap {
      max-width: 85%;
      display: flex;
      gap: 16px;

      @include media-breakpoint-down(md) {
        gap: 8px;
      }
    }

    &-image-info {
      width: 100%;

      @include media-breakpoint-down(md) {
        width: 75%;
      }
    }

    &-image {
      width: 64px;
      height: 64px;
      border-radius: 8px;
    }

    &-name,
    &-size {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.01em;
    }

    &-name {
      font-weight: 600;
      color: #545c6e;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-size {
      font-weight: 400;
      color: #747f96;
    }
  }

  &-text {
    margin: 0;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #747f96;

    &-span {
      font-weight: 600;
      color: #3538cd;
    }

    &:first-of-type {
      margin-bottom: 4px;
    }
  }

  &.active {
    border-color: #8098f9;
    background: #e0eaff;
  }
}
