.cv {
  &__form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 100%;
    &_inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      gap: 15px
    }
    &_title {
      display: flex;
      font-family: poppins, sans-serif;
      text-align: center;
      font-weight: 700;
      font-size: 2.5rem;
      color: #4a4a4a;
      letter-spacing: 1.1px;
      line-height: 50px;
      margin-top: 3%;
      margin-bottom: 25px;
    }
  }
  &__experience {
    &_responsibility {
      font-size: 12px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      margin-left: 20px;
      &:before {
        content: "•";
        color: #384347;
        display: inline-block;
        width: 1em;
        margin-left: -1em
      }
    }
  }
}
