.requests {
  &__filters-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1600px) {
      flex-wrap: wrap;

      .requests__pills {
        order: 1;
        margin-bottom: 20px;
      }
    }
  }

  &__pills {
    display: flex;
    gap: 8px;
  }

  &__filters-inner {
    display: flex;
    align-items: center;
    gap: 20px;

    .MuiBox-root {
      height: 60px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 20px;

      .MuiBox-root {
        width: 100%;
      }
    }
  }
  &-table__style:nth-child(even) {
    background-color: #f9f9fa;
  }
  &-table__style:last-child {
    margin-bottom: 100px;
  }
  &-table__wrapper {
    width: 100%;
  }
  &-table__head {
    font-weight: 600;
    font-size: 18px;
    line-height: 142%;
    color: #303540;
    margin-left: 40px;
    margin-top: 10px;
  }
  &-table__title {
    border-top: 1px solid #d4d9e2;
    border-bottom: 1px solid #d4d9e2;
  }
  &-img-modal {
    cursor: pointer;
    text-align: end;
    max-width: 300px;
    overflow-x: hidden;
    margin-bottom: 10px;
  }
  &-img {
    width: 500px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  &-datepickers {
    display: flex;
    flex-direction: row;
    width: 100%;
    width: 500px;
  }
  &-desktop-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 142%;
  }
  &-mobile-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 142%;
  }
  &-desktop-label {
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 142%;
    padding: 4px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 1620px) {
      font-size: 14px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      padding: 2px 8px;
    }
  }
  &__rejected {
    background: #fee4e2;

    &,
    &-text {
      text-transform: capitalize;
      color: #e4443b;
    }
  }
  &__approved {
    background: #d1fadf;
    &,
    &-text {
      text-transform: capitalize;
      color: #039855;
    }
  }

  &__pending,
  &__full_day_sick_leave,
  &__half_day_sick_leave {
    background: #fef0c7;
    color: #f79009;
  }
  &__unpaid_leave,
  &__half_day_unpaid_leave {
    background: #f4ebff;
    color: #7f56d9;
  }
  &__extra_sick_leave {
    background: #ebfaff;
    color: #52accf;
  }
  &__paid_leave {
    background: #ffebeb;
    color: #d95656;
  }
  &__work_off {
    background: #eeeff4;
    color: #667085;
  }
  &__day_off {
    background: #dceff8;
    color: #4365b1;
  }
  &__vacation {
    background: #f0ffeb;
    color: #039855;
  }

  &__button {
    background: #ff655c;
    border-radius: 8px;
    gap: 6px;
    padding: 18px 30px;
    color: white;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }
  &__filter-wrap {
    width: 94vw;
    margin-top: auto;
    bottom: 2%;
  }
  &__button-results {
    bottom: 5%;
    background: #444a58;
    width: 100%;
    border-radius: 8px;
    gap: 6px;
    padding: 18px 30px;
    color: white;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }
  &__button-form-apply {
    background: #444a58;
    border-radius: 8px;
    padding: 18px 30px;
    color: white;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    width: 50%;
    text-align: center;
  }
  &__button-form-cancel {
    background: white;
    border: 2px solid #eeeff4;
    border-radius: 8px;
    padding: 18px 30px;
    color: #444ce7;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    width: 50%;
    text-align: center;
  }
  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 612px;
    background-color: white;
    padding: 40px 30px;
    border-radius: 12px;
    box-shadow: 0px 3px 8px rgba(0, 38, 91, 0.1), 0px 4px 12px rgba(0, 38, 91, 0.08), 0px 1px 20px rgba(0, 38, 91, 0.04);
  }
  &__inputs_form {
    height: 430px;
  }
  &__inputs_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__modal-padd {
    font-size: 18px;
    line-height: 142%;
    letter-spacing: 0.01em;
    color: #9ea5b8;
    padding: 0px 15px 30px 15px;
    min-height: 90px;
  }
  &__modal-error {
    color: #cc0000;
    font-size: 14px;
    padding-left: 10px;
  }
  &__modal-padd-select {
    font-size: 18px;
    line-height: 142%;
    letter-spacing: 0.01em;
    color: #9ea5b8;
    min-height: 90px;
    width: 500px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &__modal-datepicker {
    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;

      & > div {
        width: 100%;
      }
    }
  }

  &__button-wrap {
    min-width: 90px;
  }
  &__modal-title {
    font-size: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #303540;
    padding: 0px 15px 30px 15px;
  }
  &__close {
    text-align: end;
    padding-bottom: 10px;
    color: #8992a6;
    cursor: pointer;
  }
  &__head-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &-mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 136%;
    letter-spacing: 0.01em;
    color: #747f96;
    margin-bottom: 60px;
  }
  &-lists-mobile-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 136%;
    letter-spacing: 0.01em;
    color: #747f96;
    padding: 20px 20px 0px 20px;
  }
  &__mobile-card {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 100px;
    }
  }

  &-mobile-label-manager {
    display: flex;
    margin-top: 5px;
  }
  &-mobile__name {
    text-decoration: none;
    border-bottom: 1px solid blue;
    font-size: 15px;
    margin-bottom: 15px;
  }
  &-mobile-manager-title {
    padding-right: 5px;
  }
  &-mobile-managers {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }
  &-mobile-manager {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
  }
  &-mobile-manager-item {
    color: #444a58;
    display: flex;
    flex-direction: column;
  }
  &-just-spc-btwn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &-mobile-label {
    border-radius: 5px;
    font-size: 12px;
    line-height: 142%;
    padding: 4px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &-wrap__mobile-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }
  &-wrap__mobile-title-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }
  &__title-mobile {
    font-size: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #303540;
    padding: 0px 15px 0px 15px;
  }
  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px;
  }
  &-list__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
    margin-left: 15px;
  }
  &__user-title-mobile {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #303540;
  }

  &__pill {
    padding: 18px 24px;
    display: flex;
    gap: 16px;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;

    font-family: 'Manrope';
    font-weight: 500;
    font-size: 18px;
    line-height: 142%;
    letter-spacing: 0.01em;
    color: #303540;

    &.pill {
      &__sick_leaves {
        border-color: #fef0c7;

        &:hover {
          background: #fffaeb;
        }
      }
      &__extra_sick_leaves {
        border-color: #fee4e2;
        &:hover {
          background: #fef3f2;
        }
      }
      &__vacations {
        border-color: #d1fadf;
        &:hover {
          background: #ecfdf3;
        }
      }
      &__paid_leaves {
        border-color: #e9d7fe;
        &:hover {
          background: #f9f5ff;
        }
      }
      &__unpaid_leaves {
        border-color: #d4d9e2;
        &:hover {
          background: #eeeff4;
        }
      }
      &__day_offs {
        border-color: #d4d9e2;
        &:hover {
          background: #e0eaff;
        }
      }
      &__work_offs {
        border-color: #d4d9e2;
        &:hover {
          background: #e0eaff;
        }
      }
    }
  }
}

.lists-counter {
  margin-left: 7px;
  background: #fef3f2;
  border-radius: 25px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ff5641;
}

.lists-counter-red {
  margin-left: 7px;
  background: #ff655c;
  border-radius: 25px;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: white;
}

.icon__add-file {
  cursor: pointer;
  margin-left: 10px;
  padding: 16px 16px;
  box-shadow: 0px 3px 5px rgba(177, 189, 210, 0.37);
  border-radius: 5px;
  background: #ffffff;
}

.button-group {
  display: contents !important;
  margin-bottom: 15px;
}

.pdd-10px {
  padding: 10px 0px;
}

.pdd-26px {
  padding: 26px 0px;
}

.flx-dir-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.flx-dir-clm {
  display: flex;
  flex-direction: column;
}

.algn-center {
  align-items: center;
}

.spc-btwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mg-btm-10 {
  margin-bottom: 10px;
}

.mobile-button {
  width: 60px;
  height: 60px;
  left: 295px;
  background: #444a58;
  border-radius: 8px;
  position: fixed;
  top: auto;
  bottom: 0px;
  z-index: 2;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 52px;
  cursor: pointer;
}

@media screen and (min-width: 360px) {
  .requests__modal {
    width: unset;
  }
}

.requests__pagination .Mui-selected {
  background-color: #fef3f2 !important;
  color: #ff5641 !important;
  border-radius: 0px !important;
}

.requests__pagination .MuiPaginationItem-root:hover {
  background-color: #fef3f2 !important;
  color: #ff5641 !important;
  border-radius: 0px !important;
}

.requests-modal__image {
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 100%;
}

.modal-img-item {
  max-width: 100vw;
  max-height: 100vh;
}

@media screen and (max-width: 1000px) {
  .flx-dir-row {
    flex-wrap: wrap;
  }
}

.request-lists-file-checkbox {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
}

.dashbord-mobile__title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 18px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: black;
}

.MuiButton-root.requests__action-button {
  width: 40px;
  height: 40px;
  min-width: auto;
  padding: 0;
}

.MuiIconButton-root.requests__action-button {
  border: 1px solid #9d9d9d;
}

.decline-button {
  color: red !important;
  border: 1px solid #fda29b !important;
  border-radius: 8px !important;
  margin-left: 12px !important;
}

.approve-button {
  color: white !important;
  background: #12b76a !important;
  border-radius: 8px !important;
}

.requests-mobile-status > .MuiIconButton-root {
  padding: 7px !important;
}
