.calendar_container {
  margin-bottom: 22% !important;

  &__select {
    width: 15%;
  }
  &__user_picker {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.fc-day-grid-container.fc-scroller {
  height: auto !important;
  overflow-y: auto;
}

.fc-scrollgrid-sync-inner {
  padding: 6%;
  display: flex;
  flex-direction: column;
}

.fc-scrollgrid-sync-inner > .fc-daygrid-day-events {
  position: absolute !important;
  overflow: auto;
  max-height: 100px;
  width: 88%;
  margin-bottom: 10px;
  bottom: 0;
}

.fc-scrollgrid-sync-inner > .fc-daygrid-day-events::-webkit-scrollbar{
  width: 5px;
}

.fc-scrollgrid-sync-inner > .fc-daygrid-day-events::-webkit-scrollbar-track{
  border-radius: 2px;
}

.fc-scrollgrid-sync-inner > .fc-daygrid-day-events::-webkit-scrollbar-thumb{
  border-radius: 2px;
  background-color: #6172F3;
}

.fc th {
  text-align: start;
}

.fc a {
  text-decoration: none;
  color: inherit;
}

.fc-col-header-cell-cushion {
  font-weight: 400;
  color: #747f96 !important;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc .fc-daygrid-day.fc-day-today {
  color: #6172f3;
  background-color: #f5f8ff;
}

.fc-daygrid-block-event {
  border-radius: 8px;
  font-weight: 600;
  border: none;
  padding: 3px 0 3px 10px;
  border-color: unset !important;
}
