.candidates-mobile-main-wrapper {
  min-width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  .candidates-mobile-item-wrapper {
    position: relative;
    width: 100%;
    max-height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 16px 8px 20px;
    isolation: isolate;
    background: #FFFFFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin-bottom: 8px;

    &::before {
      background-color: currentColor;
      content: '';
      position: absolute;
      left: 0;
      display: block;
      height: 100%;
      width: 4px;
      border-radius: 6px 0 0 6px;
    }

    .candidates-mobile-item-left {
      width: 61%;
      display: flex;
      flex-direction: column;

      .candidates-mobile-item-full-name {
        margin-top: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-decoration: underline;
        color: #6172F3;
        margin-bottom: 6px;
      }

      .candidates-mobile-item-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #747F96;
        margin: 2px 0;
      }
    }

    .candidates-mobile-item-right {
      width: 39%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .candidates-mobile-significate {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #747F96;
        margin-top: 8px;

        &::before {
          top: unset;
        }

        .candidates-mobile-significate-select {
          width: fit-content;
        }
      }
    }
  }

  .candidates-mobile-bottom-space {
    height: 50px;
  }
}
