.cv {
  &__portfolio {
    &_block {
      &_role {
        font-size: 12px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-left: 20px;
        &:before {
          content: "•";
          color: #384347;
          display: inline-block;
          width: 1em;
          margin-left: -1em
        }
      }
    }
  }
}