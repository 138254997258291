.cv {
  &__preview {
    &_wrapper {
      display: flex;
      align-items: center;
      background-color: #dbdbdb;
      padding: 10px 0;
      gap: 8px;
    }

    &_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #EEEFF4;
      box-shadow: 0 3px 5px rgba(177, 189, 210, 0.37);
      border-radius: 8px;
      margin-right: 15px;
      cursor: pointer;
    }

    &_docdownload {
      width: auto;
      border: 0;
      background: transparent;
      color: #444CE7;
      border-radius: 10px;
      transition: 0.3s linear;
      margin-right: 15px;
      cursor: pointer;
    }

    &_download {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: #FF655C;
      border-radius: 8px;
      cursor: pointer;
    }

    &_filename {
      font-size: 15px;
      color: black
    }

    &_buttons {
      display: flex;
      align-items: center;
      padding-left: 65%;
    }

    &_username {
      padding-left: 10%;
      font-weight: 600;
      font-size: 18px;
      line-height: 142%;
      color: #303540
    }
  }
}
