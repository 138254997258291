@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');
@import '~bootstrap/scss/functions';
@import './_utils';
@import 'utils/bs_variables';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
@import 'components';
@import 'redesign';

:root {
  --bs-gutter-x: 20px;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
  border-radius: 8px;
  padding: 10px 20px;
  background: #e0eaff;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #303540;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow .MuiTooltip-arrow {
  color: #e0eaff;
}

.filter-switch {
  &-container {
    height: 60px;
    display: flex;
    width: auto;
    background: #fff;
    border: 1px solid #d4d9e2;
    border-radius: 8px;
    padding: 6px;
  }

  &-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0 30px;
    cursor: pointer;

    &.active {
      background: #444a58;
      border-radius: 4px;
      color: white;
    }
  }

  @include media-breakpoint-down(md) {
    &-container {
      width: 100%;
      justify-content: center;
    }

    &-item {
      width: 100%;
      font-size: 14px;
    }
  }
}
