.manage-categories {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
  }

  &__col {
    display: flex;
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
    width: 840px;
  }

  &__table {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
    width: 840px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
  }
}
