@mixin cardStyles {
  padding: 8px 16px 8px 20px;
  background: #FFFFFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

@mixin managersAndLeavesCard {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 335px;
  word-wrap: break-word;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 8px 16px;
  margin-bottom: 8px;
}

@mixin cardUserName {
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  letter-spacing: 0.01em;
  color: #6172F3
}

.mobile {
  &_dashboard {
    &__wrapper {
     background: #F9F9FA;
     padding: 0 20px;
     min-height: 90vh;
    }

    &__filters {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__datepicker {
      width: 161px;
    }

    &__userStack {
      font-weight: 400;
      font-size: 12px;
      line-height: 136%;
      letter-spacing: 0.01em;
      color: #747F96;
      margin-top: 2px;
    }

    &__selectStatus {
      width: 240px;
    }

    &__selectFilter {
      width: 171px;
      padding: 16px 0;
    }

    &__card-busyOutstaff {
      @include cardStyles;
      border-left: 4px solid #12B76A;
    }

    &__card-readyOutstaff {
      @include cardStyles;
      border-left: 4px solid #F79009;
    }

    &__card-busyOutsource {
      @include cardStyles;
      border-left: 4px solid #6172F3;
    }

    &__cardList {
      display: flex;
      flex-direction: column;
    }

    &__card {
      @include cardStyles;

      &-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-userName {
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        letter-spacing: 0.01em;
        color: #6172F3;
      }

      &-inner {
        font-weight: 400;
        font-size: 12px;
        line-height: 136%;
        letter-spacing: 0.01em;
        color: #667085;
        margin-top: 8px;
      }

      &-manager {
        font-weight: 400;
        font-size: 12px;
        line-height: 136%;
        text-align: right;
        letter-spacing: 0.01em;
        color: #747F96;
      }

      &-managerName {
        font-weight: 500;
        font-size: 12px;
        line-height: 136%;
        text-align: right;
        letter-spacing: 0.01em;
        color: #444A58;
      }
    }
  }

  &_managers {
    &__card {
      @include managersAndLeavesCard;

      &-list {
        padding-top: 20px;
      }

      &-managerName {
        @include cardUserName
      }

      &-items {
        font-weight: 400;
        font-size: 12px;
        line-height: 136%;
        letter-spacing: 0.01em;
        color: #747F96;
      }

      &-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 136%;
        letter-spacing: 0.01em;
        color: #444A58;
        margin-top: 8px;
        margin-bottom: 5px;
      }
    }
  }

  &_leaves {
    &__card {
      @include managersAndLeavesCard;

      &-list {
        padding-top: 20px;
      }

      &-managerName {
        @include cardUserName
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 136%;
      letter-spacing: 0.01em;
      color: #747F96;
      margin: 2px 0;

      &-counter {
        font-weight: 500;
        font-size: 12px;
        line-height: 136%;
        letter-spacing: 0.01em;
        color: #444A58;
        margin-left: 5px;
      }
    }
  }
}

.no_profiles {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 136%;
  letter-spacing: 0.01em;
  color: #444A58;
  padding: 20px 0
}
