.user-profile {
  &.container-lg {
    display: flex;

    & .MuiButton-root {
      min-width: unset;

      &.d-lg-none {
        top: calc(100vh - 140px);
      }
    }
  }

  &__leaves-wrapper,
  .MuiTabs-scroller {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.navbar {
    min-height: unset;
    padding: 17px var(--bs-gutter-x, 13.28px);

    .MuiButton-root {
      border-radius: 100%;
      min-width: unset;
    }

    .navbar-title-wrap {
      display: flex;
      align-items: center;
      width: 85%;
    }

    .navbar-title {
      margin-bottom: 0;
      margin-left: 20px;
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 19px;
      line-height: 118%;
      letter-spacing: 0.01em;
      color: #303540;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .MuiButton-contained {
    background-color: #6172f3;
    text-transform: none;
    padding: 16px 20px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #ffffff;

    &:hover {
      background-color: #3538cd;
    }

    &,
    &:hover {
      box-shadow: none;
    }
  }

  &__main {
    &-info {
      flex: 1;
      border-right: 1px solid #d4d9e2;
      min-height: calc(100vh - 152px);

      &-block {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    &-header {
      display: flex;
      border-bottom: 1px solid #d4d9e2;
      margin-bottom: 40px;
      margin-right: 15%;

      & .user-profile__info-text {
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 40px;
        }
      }

      &-info {
        width: 100%;
      }
    }

    @include media-breakpoint-down(xl) {
      &-info {
        border: none;
      }

      &-header {
        margin-right: 0;
      }
    }
  }

  &__avatar {
    height: 120px;
    width: 120px;
    border-radius: 16px;
    margin-bottom: 16px;

    @include media-breakpoint-down(lg) {
      height: 100px;
      width: 100px;
    }
  }

  &__name {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #303540;
    margin-bottom: 16px;
  }

  &__info-text {
    display: flex;
    gap: 8px;
    align-items: center;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 142%;
    letter-spacing: 0.01em;

    span {
      font-weight: 600;
      color: #303540;
    }

    a {
      max-width: 47%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .btn-copy {
      margin-left: 0;
    }

    @include media-breakpoint-down(md) {
      font-size: 15px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__edit-button.MuiButton-root {
    box-shadow: 0px 3px 5px rgba(177, 189, 210, 0.37);
    border: 1px solid #eeeff4;
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }

  &__buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 40px;

    .MuiButton-root {
      padding: 16px 24px;

      &:not(:first-child) {
        background-color: #eeeff4;
        color: #8992a6;

        &:hover {
          background-color: #eaecf0;
        }
      }
    }
  }

  &__tabs-wrapper {
    flex: 3;
    padding-left: 56px;
    padding-bottom: 60px;
    position: relative;

    @include media-breakpoint-down(xl) {
      padding-left: 0;
    }

    .MuiTabs-root {
      margin-bottom: 44px;

      @include media-breakpoint-down(xl) {
        margin: 0 calc(-1 * var(--bs-gutter-x, 13.28px)) 32px;

        &,
        .MuiTabs-scroller {
          overflow-x: auto !important;
        }

        .MuiTabs-scroller {
          max-width: 100vw;
        }
      }
    }

    .MuiTab-root {
      padding: 33px 28px;

      @media (max-width: 1300px) {
        padding: 18px 20px;
      }
    }

    .MuiTab-root,
    .MuiTab-root.Mui-selected {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.16em;
      text-transform: uppercase;
      color: #303540;

      @include media-breakpoint-down(lg) {
        font-weight: 500;
        font-size: 12px;
        text-transform: capitalize;
        letter-spacing: 0.01em;
        color: #444a58;
      }
    }

    .MuiTabs-indicator {
      height: 3px;
      background-color: #ff655c;

      @include media-breakpoint-down(xl) {
        height: 2px;
      }
    }
  }

  &__active-switch {
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    font-family: 'Manrope';
    font-weight: 700;
    font-size: 29px;
    line-height: 118%;
    letter-spacing: 0.01em;
    color: #303540;
    margin-bottom: 20px;
  }

  .user-profile__card {
    padding: 8px 16px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin-bottom: 20px;

    &-manager,
    &-status,
    &-date,
    &-title {
      font-family: 'Manrope';
      font-size: 12px;
      line-height: 136%;
      letter-spacing: 0.01em;
    }

    &-title {
      font-weight: 600;
      font-size: 15px;
      text-decoration-line: underline;
      color: #6172f3;
      margin-bottom: 8px;
      text-align: left;
    }

    &-manager,
    &-status,
    &-date {
      margin-bottom: 4px;
    }

    &-manager,
    &-status {
      text-align: right;
    }

    &-date {
      font-weight: 400;
      color: #747f96;
    }

    &-manager {
      font-weight: 500;
      color: #444a58;
    }

    &-status {
      font-weight: 600;
    }
  }

  &__leaves {
    &-wrapper,
    &-type,
    &-type-top {
      display: flex;
    }

    &-counter,
    &-type-counter {
      font-family: 'Manrope';
      font-weight: 600;
    }

    &-counter {
      font-size: 23px;
      line-height: 120%;
      letter-spacing: 0.01em;
      color: #303540;
    }

    &-type-counter {
      font-size: 15px;
      line-height: 136%;
      letter-spacing: 0.01em;
      color: #747f96;
      margin: -16px 0 20px;
    }

    &-wrapper {
      gap: 18px;
      margin-bottom: 80px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 142%;
      letter-spacing: 0.01em;
      color: #667085;
    }

    &-type,
    &-type.MuiButton-root {
      flex: 1;
      padding: 30px;
      box-shadow: 0px 5px 12px rgba(54, 75, 129, 0.1);
      border-radius: 20px;
      flex-direction: column;

      @media screen and (max-width: 1620px) {
        padding: 16px;
        font-size: 14px;
      }

      font-family: 'Manrope';
      font-weight: 500;
      font-size: 15px;
      line-height: 142%;
      letter-spacing: 0.01em;
      color: #667085;
      text-transform: none;

      &-top {
        margin-bottom: 8px;
        justify-content: space-between;
        width: 100%;
      }

      &-counter {
        margin: 0;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 120%;
        text-align: right;
        letter-spacing: 0.01em;
        color: #303540;
      }
    }

    &-icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;

      &.leave-icon {
        &-sick_leaves {
          background: #fffaeb;
        }
        &-extra_sick_leaves {
          background: #fef3f2;
        }
        &-vacations {
          background: #ecfdf3;
        }
        &-paid_leaves {
          background: #f9f5ff;
        }
        &-unpaid_leaves {
          background: #eeeff4;
        }
        &-day_offs {
          background: #e0eaff;
        }
        &-work_offs {
          background: #cddcff;
        }
      }
    }

    @include media-breakpoint-down(xl) {
      &-icon {
        width: 40px;
        height: 40px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      &-wrapper {
        margin: 0 calc(-1 * var(--bs-gutter-x, 13.28px));
        padding: 0 0 20px;
        max-width: 100vw;
        overflow-x: auto;
      }

      &-type.MuiButton-root.MuiButton-text {
        min-width: 150px;
        max-width: 150px;
        padding: 16px;

        font-size: 15px;
        line-height: 136%;
        letter-spacing: 0.01em;
        align-items: flex-start;
        text-align: left;

        &.active {
          padding: 14px;
          box-shadow: none;
          border: 2px solid #fec84b;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      &-wrapper {
        padding: 0 var(--bs-gutter-x, 13.28px) 20px;
      }

      &-type.MuiButton-root {
        &-counter {
          font-size: 19px;
          line-height: 118%;
          letter-spacing: 0.01em;
        }
      }
    }
  }

  &__requests {
    .user-profile__card-title,
    .user-profile__table-td {
      &.request-full_day_sick_leave,
      &.request-half_day_sick_leave {
        color: #fdb022;
      }
      &.request-unpaid_leave {
        color: #8992a6;
      }
      &.request-vacation {
        color: #32d583;
      }
      &.request-paid_leave,
      &.request-half_day_unpaid_leave {
        color: #b692f6;
      }
      &.request-extra_sick_leave {
        color: #ff655c;
      }
      &.request-day_off {
        color: #444ce7;
      }
      &.request-work_off {
        color: #7277da;
      }
    }

    .pending,
    .approved,
    .rejected {
      padding: 4px 16px;
      border-radius: 8px;
      text-transform: capitalize;
    }

    .pending {
      color: #f79009;
      background: #fef0c7;
    }
    .approved {
      color: #039855;
      background: #d1fadf;
    }
    .rejected {
      color: #e4443b;
      background: #fee4e2;
    }

    .user-profile__card-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      letter-spacing: 0.01em;
      text-decoration: none;
    }

    .user-profile__card-status {
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      text-align: center;
      letter-spacing: 0.01em;
      padding: 4px 16px;
      border-radius: 8px;
      text-transform: capitalize;
    }
  }

  &__personal-info-button-wrap {
    top: calc(100vh - 220px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding-bottom: 20px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  // ===========================================
  // Tables styles
  // ===========================================

  &__table {
    width: 100%;

    &-thead {
      border-bottom: 1px solid #d4d9e2;
    }

    &-th {
      padding: 16px 40px;
      font-weight: 500;
    }

    &-td {
      padding: 19px 40px;
      font-weight: 500;
      color: #667085;

      &:first-child {
        font-weight: 600;
        color: #303540;
      }
    }

    &-tr:nth-child(even) {
      background: #f9f9fa;
    }

    &.personal-info {
      .user-profile__table-th {
        padding: 16px 0;

        &:last-child {
          text-align: end;
        }

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      .user-profile__table-td {
        &:first-child {
          width: 280px;
        }
      }
    }

    &.user-leaves {
      .user-profile__table {
        &-thead {
          border-top: 1px solid #d4d9e2;

          @include media-breakpoint-down(lg) {
            border: none;
          }
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        &-th,
        &-td {
          &:first-child {
            width: 200px;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      &-thead {
        border-bottom: none;
      }

      &-tr.no-bg:nth-child(even) {
        background: transparent;
      }

      &-td {
        width: auto;
        padding: 0 0 20px;

        &:last-child {
          font-weight: 400;
        }
      }

      &.personal-info {
        .user-profile__table-td:first-child {
          width: auto;
        }
      }

      &.contract-log {
        display: none;
      }

      &.cv-list {
        .user-profile__table-td {
          padding: 10px 0;
        }
      }
    }
  }
}

.contract {
  &-free {
    color: #747f96;
  }
  &-outsource,
  &-outsourse {
    color: #12b76a;
  }
  &-outstaff {
    color: #6172f3;
  }
  &-equity {
    color: #9e77ed;
  }
  &-inner-project {
    color: #e4443b;
  }
}
