.project-profile {
    width: 100%;
    margin: 20px auto;

    &_wrap {
        display: flex;
        flex-direction: row;
    }

    &-inputs {
        width: 100%;
    }

    &_mobile-links-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &_mobile-edit-wrap {
        margin: 20px auto;
        padding: 0 20px;
    }

    &_mobile-edit-links-wrap {
        display: flex;
        gap: 1rem;
        margin: 20px 20px 30px 20px;
    }

    &_edit-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    &_edit-buttons-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    &_edit-radio-buttons {
        display: flex;
        flex-direction: column;
    }

    &_edit-button {
        border: 1px solid #EEEFF4;
        box-shadow: 0px 3px 5px rgba(177, 189, 210, 0.37);
        border-radius: 8px;
        padding: 13px;
        cursor: pointer;
        margin-right: 22px;
    }

    &_links-wrap {
        width: 48%;
    }

    &_links-item {
        margin-bottom: 10px;
    }

    &_title-inputs {
        font-weight: 600;
        font-size: 18px;
        line-height: 142%;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        margin-bottom: 18px;
    }

    &_mobile-edit-button {
        border: 1px solid #EEEFF4;
        box-shadow: 0px 3px 5px rgba(177, 189, 210, 0.37);
        border-radius: 8px;
        padding: 13px;
        cursor: pointer;
    }

    &_item {

        font-weight: 400;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        color: #667085;
        margin-left: 12px;
    }

    &_title-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &_general {
        width: 40%;

        &-title {
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 16px;
        }

        &-subtitle {
            font-weight: 600;
            font-size: 18px;
            line-height: 142%;
        }

        &-mobile-subtitle {
            font-weight: 600;
            font-size: 18px;
            line-height: 142%;
            margin-bottom: 20px;
        }
    }

    &_additional {
        margin-left: 84px;
        margin-top: 33px;

        &-tab {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.16em;
            padding: 0px 32px 33px 32px;
            cursor: pointer;
            margin-bottom: 60px;

            &-active {
                border-bottom: 3px solid #FF655C;
            }

            &-button {
                display: flex;
                flex-direction: row;
            }
        }

        &-tab:hover {
            color: #FF655C;
        }
    }

    &_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &_url {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &_item-presents {
        width: 120px;
        background-color: #6172F3;
        border-radius: 6px;
        padding: 16px 30px;
        margin: 20px 4px 0px 4px;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        text-decoration: none;
        border: 1px solid #6172F3;
    }

    &_item-none {
        width: 120px;
        background-color: #D4D9E2;
        border-radius: 6px;
        padding: 16px 30px;
        margin: 20px 4px 0px 4px;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        text-decoration: none;
        border: 1px solid #D4D9E2;
        pointer-events: none;
        cursor: default;
    }

    &_item-presents:hover {
        width: 120px;
        background-color: white;
        border-radius: 6px;
        padding: 16px 30px;
        margin: 20px 4px 0px 4px;
        display: flex;
        align-items: center;
        color: #6172F3;
        justify-content: center;
        text-decoration: none;
        border: 1px solid #6172F3;
        cursor: pointer;
    }

    &_client-info-desktop {
        height: 64px;
        padding: 19px 24px 19px 40px;
        background-color: white;
        display: flex;
        flex-direction: row;
    }

    &_client-info-desktop-company {
        height: 64px;
        padding: 19px 24px 19px 40px;
        background-color: #F9F9FA;
        display: flex;
        flex-direction: row;
    }

    &_client-info-desktop-even {
        background-color: #F9F9FA;
    }

    &_additional-project-wrap {
        width: 65%;
    }

    &_additonal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        color: #303540;
        width: 33%;
    }

    &_additional-item {
        font-weight: 500;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        color: #667085;
    }

    &_additional-item-email {
        font-weight: 600;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #6172F3;
    }

    &_additional-item-mobile-email {
        font-weight: 600;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #6172F3;
        margin-left: 12px;
    }

    &_manager {
        width: 100%;
        border-bottom: 1px solid #D4D9E2;
    }

    &_manager-name {
        font-weight: 600;
        font-size: 23px;
        line-height: 120%;
        letter-spacing: 0.01em;
        color: #303540;
        padding: 19px 24px 0px 40px;
    }

    &_position {
        font-weight: 400;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        color: #747F96;
        padding: 0px 24px 22px 40px;
    }

    &_contracts-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 142%;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #6172F3 !important;
        width: 25%;
    }

    &__mobile {

        &-wrapper {
            margin: 32px auto;
            padding: 0px 20px;
        }

        &-title {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            letter-spacing: 0.01em;

            &-item {
                padding: 18px 0px;
                border-bottom: 1px solid grey;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                width: 100%;
                text-align: center;
            }
        }

        &-title-active {
            padding: 18px 0px;
            border-bottom: 3px solid #FF655C;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            width: 100%;
            text-align: center;
        }
    }

    &__card {
        &-wrap {
            padding: 8px 16px;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            margin-bottom: 15px;
        }

        &-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &-name {
            font-weight: 600;
            font-size: 15px;
            line-height: 136%;
            letter-spacing: 0.01em;
            text-decoration-line: underline;
            color: #6172F3;
            margin-bottom: 8px;
        }

        &-additional-info {
            font-weight: 400;
            font-size: 12px;
            line-height: 136%;
            letter-spacing: 0.01em;
            color: #747F96;
        }

        &-status {
            font-weight: 600;
            font-size: 12px;
            line-height: 136%;
            letter-spacing: 0.01em;
        }
    }
}

.project-profile_edit-buttons .Mui-checked {
    color: red !important;
}


.project-profile_edit-buttons-mobile .Mui-checked {
    color: red !important;
}

.edit-profile__buttons-wrap {
    display: flex;
    gap: 1rem;
    margin-top: 20px;
}

.lbl-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #444A58;
}

.mrg-bottm4px {
    margin-bottom: 4px;
}

.mrg-bottm10px {
    margin-bottom: 10px;
}

.mrg-bottm24px {
    margin-bottom: 24px;
}

.heigth60px {
    height: 60px;
}

.select-edit-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-links-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.undeline-general {
    border-bottom: 1px solid #D4D9E2;
}

.horyzontal-line-general {
    border-right: 1px solid #D4D9E2;
}

.margin-bottom-8px {
    padding-bottom: 8px;
}

.margin-bottom-28px {
    margin-bottom: 28px;
}

.margin-top-40px {
    margin-top: 40px;
}

.margin-bottom-40px {
    margin-bottom: 40px;
}
