@import "./styles/main";

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

a:hover {
  text-decoration: none !important;
}

hr {
  position: relative;
  background-color: cadetblue;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  width: 90%;
  max-width: 700px;
  margin: 2em auto;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  justify-content: center;
}

.error {
  position: absolute;

  color: red;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translate(0);
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: none;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

input[placeholder] {
  font-size: 1.1em;
}

.container {
  height: calc(100vh - 80px - 40px);
  position: relative;
  margin: 20px auto;
  border-radius: 5px;

  @include media-breakpoint-down(sm) {
    height: auto;
    padding-bottom: 20px;
  }
}

.header {
  color: black;
  padding: 2em;
  text-align: left;
  font-family: Tahoma, sans-serif;
}

.inputContainer {
  padding: 15px;
}

.inputContainer {
  padding: 10px;
  width: 100%;
  border-radius: 25px;
  box-sizing: border-box;
}

.alert {
  margin: 0 auto;
  max-width: 480px;
}

.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.datePicker {
  padding: 6em 0;
}

.datePickerForm {
  text-align: center;
  padding-bottom: 2em;
}

.recordTable {
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
}

.deleteBtn {
  margin-left: 10px;
  opacity: 0.6;
  font-size: 1.2em;
  cursor: pointer;

  transition-duration: 0.3s;

  &__notRecords {
    font-size: 1.2em;
    color: red;
  }
}

.deleteBtn:hover {
  opacity: 1;
}

.editBtn {
  margin-right: 10px;
  opacity: 0.6;
  font-size: 1.2em;
}

.editBtn:hover {
  opacity: 1;
}

.btnLeft {
  opacity: 0.6;
  font-size: 1.6em;
}

.btnLeft:hover {
  opacity: 1;
}

.btnRight {
  opacity: 0.6;
  font-size: 1.6em;
}

.btnRight:hover {
  opacity: 1;
}

.btnArrowRight {
  padding-top: 0.4em;
  text-align: left;
}

.btnArrowLeft {
  padding-top: 0.4em;
  text-align: right;
}

.total {
  text-align: right;
}

.totalText {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #303540;
  width: 100%;
}

.filtersLabel {
  padding-top: 0.2em;
}

.filtersInput {
  text-align: left;
}

.reportsTable {
  margin: 6em 0;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
}

.reportsLabel {
  padding-top: 0.2em;
  font-size: 1.6em;
  color: #525252;
}

.mg-left {
  margin-left: 10px;
}

.Outstaff_color {
  background-color: #d9ead3;
}

.Outsourse_color {
  background-color: #d0e0e3;
}

.Available_color {
  background-color: #fff2cc;
}

.csv-container {
  display: flex;
  justify-content: flex-end;
}

.csv-wrapper {
  padding: 2em 1em 0 0;
}

.header-item__checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-list__multiply {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.header-item__name-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.header-item__cv-link {
  font-size: 20px;
  font-weight: bold;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.bg_color_white {
  background-color: white;
}

.table-contracts-page {
  display: flex;
  flex-direction: initial;
  justify-content: space-evenly;
  align-items: center;
}

.monthly_report_filter {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.patch_datepicker  {
  width: 100%;
}

.monthly_report_filters {
  flex-wrap: nowrap;
}

.action_icon_admin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.monthly_calendar_title {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  color: black;
  font-size: 18px;
}

.monthly_calendar_project {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 18px;
}

.monthly_calendar_project-button {
  margin: 5px 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  padding: 5px 25px;
  border-radius: 7px;
}

.monthly_calendar_project-button-chosen {
  margin: 5px 20px;
  background-color: white;
  color: 007bff;
  cursor: pointer;
  padding: 5px 25px;
}

.name_of_month {
  font-size: 24px;
}

.holiday-title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 10px;
  padding-right: 16px;
  background-color: white;
  color: #6172F3;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spinner-custom {
  margin-top: 30px;
}

.permission_message{
  margin-left: 5rem;
  margin-top: 2rem;
  font-size: 20px;
}
