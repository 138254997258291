.project-info-modal {
  &__tittle {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 19px;
    color: $Azure;
  }

  &__text {
    margin-left: 20px;
  }

  &__form {
    @include form-control
  }

  &__form-group {
    margin-bottom: 1rem;
  }
}
