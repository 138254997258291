.manage-customers {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
  }

  &__culomn {
    flex-direction: column;
  }

  &__create-button-mibile {
    width: 60px;
    height: 60px;
    left: 295px;
    background: #444A58;
    border-radius: 8px;
    position: fixed;
    top: auto;
    bottom: 0px;
    z-index: 2;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 52px;
    cursor: pointer;
  }

  &__row-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  &__wrapper {
    margin: 20px auto;
    border-radius: 5px;
  }

  &__row-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  &__edit-icon {
    margin-left: 20px;
  }

  &__row-name {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }

  &__dropdow-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    color: grey;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    letter-spacing: 0.01em;
    color: #747F96;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  &__col {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
  }

  &__button {
    width: 100px;
    height: 18px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__filters-column {
    justify-content: left;
    padding: 1.5rem;
    border-radius: 0.25rem;
    text-align: center;
  }

  &__select {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 18px 6px 6px;
    gap: 24px;
    width: 550px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #D4D9E2;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__navbar-filters-icon {
    margin-left: 70px;
  }

  &__button-position {
    margin-top: 20px;
  }

  &__select-mobile {
    margin: 15px 0px 15px 0px
  }

  &__select-style {
    margin-left: 20px;
    margin-top: 15px;
  }

  &__table {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
    width: 100%;
    
  }

  &__input-mobile {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px;
    gap: 12px;
    width: 335px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #D4D9E2;
    border-radius: 6px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  &__input-wrapper {
    width: 335px;
    margin-top: 5px;
    margin-left: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    height: 35px;
  }

  &__card {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 8px 16px;
    isolation: isolate;
    width: 335px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  &__number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: 40px;
    height: 35px;
    background: #FEF3F2;
    border-radius: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: #FF5641;
  }

  &__button-mobile {
    width: 60px;
    height: 60px;
    left: 290px;
    background: #444A58;
    border-radius: 8px;
    border: 0px;
    position: fixed;
    top: auto;
    bottom: 0px;
    z-index: 2;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 52px;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &__body {
    background-color: #F9F9FA;
    min-height: 600px;
  }

  &__dropdown {
    width: 60px;
    height: 60px;
    margin-top: 15px;
    margin-left: 70px;
    border: 1 solid black;
    display: block;
    overflow: hidden;
  }

  &__button-filters {
    position: absolute;
    left: 12.5%;
    right: 12.5%;
    top: 20.83%;
    bottom: 12.5%;
    border: 2px solid #6772C9;
  }

  &__pagination {
    width: 40px;
    height: 40px;
    border: 0px;
    padding: 8px 16px;
    margin-right: 5px;
    background: #FEF3F2;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: center;
  }

  &__pagination-button {
    width: 6px;
    height: 20px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FF5641;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &__filters-mobile {
    display: flex; 
    flex-direction: column; 
    gap: 10px;
  }

  &__filters-mobile-button {
    display: flex; 
    gap: 1rem; 
    margin-top: 40px;
  }

  &__row-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.flx-dir-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.dropbtn {
  background-color: white;
  color: grey;
  font-size: 10px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}
