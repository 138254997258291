.notifications {
  max-width: 400px;
  min-width: 370px;
  max-height: 400px;
  background-color: #fff;
  margin: 0 -300px;
  padding: 0;
  border-radius: 14px;
  box-shadow: 0 5px 12px rgba(54, 75, 129, 0.1);
  overflow-x: auto;
  &__item {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    min-height: 64px;
    margin: 5px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    &-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__title {
    font-size: 18px;
    color: #303540;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__wrapper {
    width: 100%;
    background: #ffffff;
    padding: 20px;
  }
}

.notification {
  &__data {
    width: 100%;
  }
  &__user {
    display: flex;
    justify-content: space-between;
  }
  &__link {
    text-decoration: none;
  }
  &__timestamp {
    display: flex;
    width: 20%;
    justify-content: end;
  }
  &__message {
    font-size: 14px;
    color: #747f96;
  }
}

.bellIcon {
  position: relative;
}
.bellIcon_after {
  position: relative;
  &::after {
    content: '';
    top: 12px;
    left: 45px;
    position: absolute;
    border-radius: 50%;
    background-color: #FF5641;
    width: 10px;
    height: 10px;
  }
}

.navbar__title-request > .bellIcon_after {
  position: relative;
  &::after {
    content: '';
    top: 12px;
    left: 45px;
    position: absolute;
    border-radius: 50%;
    background-color: #FF5641;
    width: 10px;
    height: 10px;
  }
}

.notifications::-webkit-scrollbar {
  width: 8px;
}

.notifications::-webkit-scrollbar-track {
  background: white;
}

.notifications::-webkit-scrollbar-thumb {
  background-color: #6172f3;
  border-radius: 200px;
  height: 100px;
}

.new-notifications__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #32d583;
  margin-bottom: 15px;
}
