.admin-filters {
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: $layout-mobile) {
      display: block;
    }
  }

  &__col-2 {
    margin: 0 30px;
    max-width: 16.7%;
  }

  &__col-3 {
    flex: 0 0 25%;
    max-width: 95%;

    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__col-4 {
  margin: 0 auto;

    &--checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }


  &__select {
    @include form-control;

    @media (max-width: $layout-mobile) {
      margin: 10px;
    }
  }
}
