.adminDatePicker {
  @include datePicker;

  &__data {
    @include form-control;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 ;
    margin-left: 0;
    justify-content: center;
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  &__total {
    @include totalText;
  }
}
