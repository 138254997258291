.user-report{
    &__title{
        margin: 20px;
        font-weight: 600;
    }

    &__title_counter{
        margin-left: 7px;
        background: #FEF3F2;
        border-radius: 25px;
        padding: 8px 12px;
        font-weight: 500;
        font-size: 14px;
        text-align: center; 
        color: #FF5641;
    }

    &__tabs{
        margin: 0;
        padding: 18px 6px;
    }
    
    &__mobile_tabs{
        margin: 0;
        display: flex;
        padding: 5px 6px;
    }

    &__mobile_tabs > li{
        text-align: center;
        justify-content: center;
        display: flex;
        width: 100%;
        padding: 13px 0;
    }

    &__filters{
        &_container{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &_select{
            width: 20%;
        }
        &_mobile_select{
            width: 100%;
        }
        &_left{
            display: flex;
            gap: 1rem;
        }
        &_mobile{
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__footer{
        display: flex;
        justify-content: center;
        margin-left: auto;
        width: 60%;
        align-items: center;
    }

    &__mobile_footer{
        background-color: #fff;
    }

    &__footer > *{
        display: flex;
    }

    &__footer > nav{
        margin-right: auto;
    }

    &__footer > div{
        margin-left: auto;
    }

    &__mobile_bg{
        background-color: #F9F9FA;
    }

    &__mobile_bg > nav{
        background-color: #F9F9FA;
        margin-bottom: 0;
        padding-bottom: 30px;
    }

    &__mobile_filters{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 0;
    }

    &__mobile_filters > svg{
        width: 30px;
        height: 30px;
    }

    &__mobile_nodata{
        color: #747F96;
        padding-top: 80%;
        padding-bottom: 100%;
        text-align: center;
    }

    &__counter{
        margin-left: 7px;
        background: #FF5641;
        border-radius: 15px;
        padding: 5px 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: center; 
        color: #FEF3F2;
    }

    &__wrapper{
        align-items: initial;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px !important;
    }
}
