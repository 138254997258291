@mixin default_display {
  display: flex;
  flex-direction: column;
}
@mixin default_items__styles {
  font-weight: 500;
  font-size: 15px;
  color: #545C6E;
}
@mixin default_names__styles {
  font-weight: 600;
  font-size: 15px;
  color: #303540;
}
@mixin default_text__styles {
  font-weight: 400;
  font-size: 15px;
  color: #545C6E;
  line-height: 136%;
}
@mixin default_title__styles {
  font-weight: 700;
  color: #303540;
  line-height: 118%;
}

.mobile_preview {
  @include default_display;
}
.cv {
  &_nav {
    &__item {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #444A58;
      text-decoration: none;

      &_active {
        text-decoration: underline 2px solid red;
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 450px;
      border-bottom: 1px solid #D4D9E2;
      overflow-x: auto;
      padding: 24px 15px;
    }
  }

  &_header {
    @include default_display;
    &__inner {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  &_block__title {
    @include default_title__styles;
    font-size: 19px;
    margin-bottom: 7px;
  }

  &_avatar {
    width: 104px;
    height: 100px;

    &__image {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  &_name {
    color: #303540;
    font-size: 29px;
    font-weight: bold;
    margin-left: 15px;
  }

  &_summary {
    font-weight: 500;
    font-size: 12px;
    line-height: 136%;
    letter-spacing: 0.01em;
    color: #545C6E;
    margin: 3px 0 5px;
  }
}

.user {
  &_data {
    @include default_items__styles;

    &__wrapper {
      @include default_display;
    }

    &__item {
      @include default_names__styles;
    }
  }
}

.skills {
  &_wrapper {
    @include default_display;
  }
}

.skill {
  @include default_items__styles;
  margin-left: 20px;

  &_name {
   @include default_title__styles;
    font-size: 15px;
  }

  &_items {
    @include default_items__styles;
    margin: 4px 0;
  }

  &_item {
    @include default_display;
    position: relative;
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #303540;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
}

.language {
  &_item {
    @include default_display;
    margin: 6px 0
  }

  &_name {
    @include default_names__styles
  }

  &_level {
    @include default_items__styles
  }
}

.certificate {
  &_item {
  margin: 5px 0
  }

  &_name {
    font-weight: 600;
    font-size: 17px;
    line-height: 118%;
    color: #303540;
  }

  &_url {
    @include default_names__styles;
    text-decoration: none;
  }
}

.books {
  &_wrapper {
  }

  &_list {
    @include default_display;
  }
}

.book {
  &_item {
    display: flex;
    min-height: 75px;
    margin: 5px 0
  }

  &_cover {
    width: 20%;
  }

  &_img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 8px
  }

  &_inner {
    @include default_display;
    margin-left: 20px;
    width: 80%
  }

  &_title {
    font-weight: 600;
    font-size: 17px;
    line-height: 118%;
    color: #303540;
  }

  &_author {
    @include default_items__styles;
  }
}

.experiences {
  &_list {
    @include default_display;
  }
}

.experience {
  &_item {
    @include default_display;
    margin-bottom: 20px;
  }

  &_jobTitle {
    font-weight: 700;
    @include default_title__styles;
  }

  &_company {
    @include default_items__styles;
    line-height: 136%;
  }

  &_interval {
    font-weight: 600;
    font-size: 12px;
    line-height: 136%;
    color: #303540;
    margin: 10px 0
  }

  &_responsibilities {
    &__list {
      @include default_display;
    }
  }

  &_responsibility {
    @include default_text__styles;
    position: relative;

    &__item {
      margin-left: 12px;
      &:before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #545C6E;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
}

.university {
  &_item {
    @include default_display;
    margin-bottom: 20px;
  }

  &_name {
    @include default_title__styles;
    font-size: 19px;
    margin: 3px 0;
  }
}

.education {
  &_level {
    @include default_items__styles;
    line-height: 136%;
  }

  &_interval {
    font-weight: 400;
    font-size: 12px;
    line-height: 136%;
    color: #303540;
    margin: 10px 0
  }
}

.projects {
  &_wrapper {
    @include default_display
  }
}

.project {
  &_item {
    @include default_display;
    margin-bottom: 20px;
  }

  &_title {
    @include default_title__styles;
    font-size: 19px;
  }

  &_role {
    @include default_items__styles;
    line-height: 136%;
    margin-top: 4px;
  }

  &_stack {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
    }

    &__item {
      padding: 10px 20px;
      gap: 10px;
      background: #EEEFF4;
      border-radius: 8px;
      margin-right: 8px;
      margin-top: 5px;
      color: #444A58;
      font-size: 15px;
    }
  }

  &_link {
    text-decoration: none;
  }

  &_description {
    @include default_text__styles;
    letter-spacing: 0.01em
  }

  &_responsibilities {
    @include default_text__styles;
    letter-spacing: 0.01em
  }

  &_team {
    @include default_text__styles;

    &__title {
      @include default_title__styles;
      font-size: 15px;
      margin: 5px 0
    }
  }
}

.responsibility__title {
  @include default_title__styles;
  font-size: 15px;
  margin: 5px 0
}

.course {
  &_item {
    @include default_display;
    margin-bottom: 15px;
  }

  &_name {
    @include default_title__styles;
    font-size: 19px;
    margin-bottom: 3px;
  }

  &_license {
    @include default_items__styles;
  }

  &_issued {
    @include default_items__styles;
    margin: 5px 0
  }

  &_stack {
    @include default_items__styles;
  }
}

.no_courses {
  @include default_names__styles;
  font-size: 15px;
  margin: 0 auto;
}

.mobile_tabs .Mui-selected {
  background-color: white !important;
}

.mobile_tabs .MuiButtonBase-root {
  color: #444A58 !important;
}
