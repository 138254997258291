.manage-project {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
  }

  &__col {
    display: flex;
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
  }

  &__table {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 0.25rem;
    width: 90%;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
  }
}

.status_free {
  color: #FF655C;
}

.status_inner {
  color: #FDB022;
}

.status_free-mob {
  font-weight: 600;
  font-size: 12px;
  line-height: 136%;
  letter-spacing: 0.01em;
  color: #FF655C;
}

.status_inner-mob {
  font-weight: 600;
  font-size: 12px;
  line-height: 136%;
  letter-spacing: 0.01em;
  color: #FDB022;
}
