.header-item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-self: stretch;
  font-size: 20px;
}

.header-more-info {
  margin-bottom: 20px;
}

.profile__cv-buttons {
  color: white;
}
