.mobile-card{
    &__user_name{
        margin-left: 20px;
    }
    &__container{
        background: #FFFFFF;
        border: 1px solid #EEEFF4;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        padding: 5px 20px;
        margin-bottom: 10px;

        &_header{
            display: flex; 
            justify-content: space-between; 
            align-items: center;
        }

        &_body{
            color: #667085
        }
    }
}

.report-card{
    margin: 20px;
    display: flex;
    justify-content: space-between;

    &__header{
        margin-bottom: 10px;
        margin-top: 5px;
    }

    &__info{
        display: flex;
        flex-direction: column;
    }

    &__hours{
        margin: 5px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__hours > *{
        margin: 0;
    }

    &__hours > span{
        margin-top: auto;
    }

    &__hours > :first-child{
        color: #667085;
    }
}
