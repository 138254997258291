.monthly-report-filters {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: $layout-mobile) {
    display: block;
    width: 100%;
  }

  &__col {
    flex: 0 0 25%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @media (max-width: $layout-mobile) {
      margin-top: 10px;
    }
  }

  &__input {
    @include form-control
  }

  &__select {
    @include form-control;
  }
}
