.record-table {
  &__table {
    width: 100%;
    margin-bottom: 1rem;
    color: $shark;

  }

  &__table td,
  &__table th {
    border: 1px solid $geyser;
    padding: 0.75rem;
  }

  &__table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__table tbody tr:hover {
    color: $shark;
    background-color: rgba(0, 0, 0, 0.075);
  }
}
