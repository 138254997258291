.double_select{
    &__container{
        display: flex;
        flex-direction: row;
        border: 1px solid #D4D9E2;
        border-radius: 8px;
        padding: 5px;
        width: 100%;
    }

    &__mobile_container{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__input{
        width: 100%;
    }

    &__input:first-child{
        width: 30%;
    }
}
