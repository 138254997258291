.checkbox__icon{
  width: 20px;
  height: 20px;
}

.checkbox__icon path {
  stroke: #FF655C;
}

.checkbox__icon rect {
  stroke: #FF655C;
  rx: 5px;
}
