.table-heading {
  padding: 17px 40px;
  line-height: 1;

  span {
    display: flex;
    font-size: 18px;
    font-weight: 600;

    small {
      display: block;
      font-size: 14px;
      font-weight: 500;
      padding: 3px 12px;
    }
  }
}

.table-wrapper{
  overflow-x: auto;
  margin-bottom: 20px;
  
    th {
      color: #747F96;
      font-weight: 500;
      font-size: 14px;
    }

    tr {
      &:nth-child(even) {
        background-color: #F9F9FA;
      }
    }
    
    td {
      color: #667085;
      font-size: 18px;
      line-height: 1.5;
      padding: 19px 30px !important;
      white-space: nowrap;
      vertical-align: baseline;
    
      &:first-child {
        width: 20px;
      }
    
      &:nth-child(2) {
        color: #303540;
        font-weight: 500;
      }

      &:nth-child(3) {
        max-width: 300px;
      }
    }

    thead {
        tr {
            border-top: 2px solid #D4D9E2;
            border-bottom: 2px solid #D4D9E2;

            th {
            padding: 15px 30px !important;
            }
        }
    }
}

.table-options {
  button {
    background-color: transparent;
    border-width: 0;
    height: 30px;
    width: 30px;
    margin-right: 10px;

    svg {
      height: 20px;
      width: auto;
    }
  }
}

.candidate-contacts {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    margin-bottom: 9px;
  }
}

.candidates-table {
  td {
    vertical-align: initial;
    padding: 9px 30px !important;
  }
}

.bg-preoffer {
  background-color: #E0EAFF !important;
}

.bg-test_task {
  background-color: #FFFAEB !important;
}

.bg-interviewing {
  background-color: #F4EBFF !important;
}

.bg-rejected {
  background-color: #FEF3F2 !important;
}

.bg-hired {
  background-color: #ECFDF3 !important;
}


.react-bootstrap-table table {
  table-layout: auto;
}
